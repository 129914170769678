const logo = require('../../assets/realpeople.png');
const img1 = require('../../assets/img1.png');
const img2 = require('../../assets/img2.png');
const img3 = require('../../assets/img3.png');

export default {
  logo,
  img1,
  img2,
  img3,
};
